<template>
  <van-nav-bar>
    <template #left>
      <van-image :src="require('../assets/home/logo.png')" width="140px" height="40px" fit="contain"></van-image>
    </template>
    <!-- <template #right>
      <van-image :src="require('../assets/home/app.png')" width="46px" height="24px" fit="contain"></van-image>
    </template> -->
  </van-nav-bar>
  <van-swipe :autoplay="3000">
    <van-swipe-item v-for="item of swipeBannerList" :key="item.Id">
      <van-image :src="item.ImageUrl" width="100%" fit="contain"></van-image>
    </van-swipe-item>
  </van-swipe>
  <router-link to="/noticDetails">
    <van-notice-bar left-icon="volume-o" scrollable background="#ffffff">
      <template #default>
        <span class="marquee-item" v-for="(item, index) of noticeBarList" :key="index" style="margin-right: 375px">{{ item.MessageContent }}</span>
      </template>
    </van-notice-bar>
  </router-link>
  <div class="tabs">
    <div :class="['item', item.code, { active: activeTab == item.code }]" @click="changeTab(item)" v-for="item of opcGames" :key="item.code">
      <van-image :src="require(`../assets/logo_${item.code}.png`)" width="38px" height="38px" fit="cover"></van-image>
      <div class="name">{{ item.name }}</div>
      <div class="dateTime" v-if="item.code !== 'game_tz' && item.code !== 'game_pc28'">{{ $moment(item.DrawTS).format("MM月DD日") }}开奖</div>
      <div class="dateTime" v-else>{{ item.DrawTS }}</div>
    </div>
  </div>
  <div class="latest">
    <div class="current">
      <div class="lotter">
        <img :src="require(`../assets/logo_${activeTab}.png`)" class="logo" />
        <div class="lotteryName">{{ opcGames[activeTab].name }}</div>
      </div>
      <div class="next" v-if="activeTab !== 'game_pc28'">
        <div class="lastRoundNo">
          距离第<span>{{ latestRound.RoundNo }}</span
          >期开奖
        </div>
        <FilpClock :seconds="latestRound.LatestTimeDiff" @finish="onFinish"></FilpClock>
        <div class="lastDate" v-if="activeTab !== 'game_pc28' && activeTab !== 'game_tz'">{{ $moment(latestRound.DrawTS).format("YYYY/MM/DD dddd HH点mm分") }}</div>
        <div class="lastDate" v-else>{{ $moment(latestRound.DrawTS).format("YYYY/MM/DD HH:mm:ss") }}</div>
      </div>
      <div class="next-tzpc" v-else>
        <div>
          <div class="tip-title">开奖时间</div>
          <div class="tip-text">首期开奖时间不固定，依官方开出时间为主</div>
        </div>
        <div>
          <div class="tip-title">开奖频率</div>
          <div class="tip-text">每天402期,3分30秒一期</div>
        </div>
      </div>
    </div>
  </div>
  <div class="tabbar">
    <div :class="['item', { active: tabIndex == 0 }]" @click="tabIndex = 0">
      <div class="history">开奖历史</div>
      <div class="more-history" v-if="activeTab == 'game_pc28' || activeTab == 'game_tz'">
        <router-link :to="{ name: 'moreHistory', params: { id: activeTab == 'game_pc28' ? 11 : 9999, name: activeTab == 'game_pc28' ? 'PC28' : '天子六合彩' } }">
          <span class="more">更多 <van-icon name="arrow" /></span>
        </router-link>
      </div>
    </div>
    <!-- <div :class="['item', { active: tabIndex == 1 }]" @click="pushPath('/moreHistory')">走势图</div> -->
  </div>

  <van-skeleton title :row="5" :loading="loading">
    <div class="tab-content" v-if="activeTab !== 'game_pc28'">
      <div class="table" v-for="(item, index) of gameHistoryList" :key="index" v-show="tabIndex == 0 && gameHistoryList.length">
        <div class="table-header">
          <div class="header-roundNo">
            <span>第</span>
            <p>{{ item.RoundNo }}</p>
            <span>期开奖结果</span>
          </div>
          <div class="header-dateTime" v-if="activeTab !== 'game_tz'">{{ $moment(item.DrawTS).format("YYYY/MM/DD") }}</div>
          <div class="header-dateTime" v-else>{{ $moment(item.DrawTS).format("YYYY/MM/DD HH:mm:ss") }}</div>
        </div>
        <div class="table-body">
          <div class="item" v-for="(item, i) of gameNumList[index]" :key="i">
            <div :class="[`ball ball-${item.color}`]">
              <div class="ball-number">{{ item.number }}</div>
            </div>
            <div class="ball-class">{{ item.zodiac }}/{{ item.fiveElements }}</div>
          </div>
          <div class="item">
            <div class="ball">
              <div class="icon-add">+</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-show="tabIndex == 0 && !gameHistoryList.length">
        <div class="table-header">等待开奖。。。</div>
      </div>
      <div class="table" v-show="tabIndex == 1">
        <div class="table-list">
          <div class="list list-title">
            <div class="item item-roundNo">期号</div>
            <div class="item item-title">特码</div>
            <div class="item item-num" v-for="item of tmList" :key="item">{{ item }}</div>
          </div>
          <div class="list" v-for="(resItem, index) of gameNumList" :key="index">
            <div class="item item-roundNo">{{ resItem[6].roundNo }}</div>
            <div class="item item-title">
              <span :class="[`tm tm-${resItem[6].color}`]">{{ resItem[6].number }}</span>
            </div>
            <div class="item item-num" v-for="item of tmList" :key="item">
              <span :class="[`tm tm-${item == resItem[6].number ? resItem[6].color : item}`]">{{ item == resItem[6].number ? item : "--" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content" v-else>
      <div class="table" v-show="tabIndex == 0 && gameHistoryList.length">
        <van-row class="row-header">
          <van-col span="6">期号</van-col>
          <van-col span="6">时间</van-col>
          <van-col span="6">开奖号码</van-col>
          <van-col span="6">总和</van-col>
        </van-row>
        <van-row v-for="(item, index) of gameHistoryList" :key="index">
          <van-col class="table-col" span="6">{{ item.RoundNo }}</van-col>
          <van-col class="table-col" span="6">{{ item.DrawTS.split("T")[1] }}</van-col>
          <van-col class="table-col" span="6">
            <span v-for="(num, i) of formatPC28Num(item.DrawResult)" :key="i">{{ num }}</span>
          </van-col>
          <van-col class="table-col" span="6">{{ numberSum(item.DrawResult) }}</van-col>
        </van-row>
      </div>
      <div class="table" v-show="tabIndex == 0 && !gameHistoryList.length">
        <div class="table-header">等待开奖。。。</div>
      </div>
      <div class="table" v-show="tabIndex == 1">
        <div class="table-header">
          <!-- <div class="table-list">
            <div class="table-title">
              <div class="dateNum">期号</div>
              <div class="tmNum">特码</div>
            </div>
            <div class="table-content">
              <div class="dateNum">期号</div>
              <div class="tmNum">特码</div>
            </div>
            <div class="table-item">
              <div class="item-num" v-for="(item, index) of tmList" :key="index">{{ item }}</div>
            </div>
          </div> -->
          <div>敬请期待...</div>
        </div>
      </div>
    </div>
  </van-skeleton>
  <van-dialog v-model:show="showPopupMsg" class="popupMsg" :title="popupMessage.length ? popupMessage[popupMsgIndex].MessageTitle : ''" show-cancel-button @close="closeMessagePopup">
    <template #default>
      <div class="dialog-text">
        <div class="msg-dateTime">{{ popupMessage.length ? $moment(popupMessage[popupMsgIndex].AddTime).format("YYYY/MM/DD HH:mm:ss") : "" }}</div>
        <div class="msg-content" v-html="popupMessage.length ? popupMessage[popupMsgIndex].MessageContent : ''"></div>
      </div>
      <div class="dialog-switch">
        <div class="switch-text">今日不再提示</div>
        <van-switch v-model="todayShowPopMsg" @change="switchTodayShowMsg($event)" size="16px" active-color="#4880e0" inactive-color="#d5d5d5" />
      </div>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <van-button square class="btn-next" type="primary" :disabled="popupMessage.length === 1" @click="nextMessage"> 下一条 </van-button>
        <van-button square class="btn-close" color="#ffba48" @click="closeMessagePopup"> 关闭 </van-button>
      </div>
    </template>
  </van-dialog>
</template>

<script>
import { getScrollingMessage, listLotteryDrawResult, getAdvertiseList, getLatestRound, getLatestTime, getPopupMessage } from "@/api";
import numData from "../number/thisYear";
import FilpClock from "@/components/FlipClock.vue";

export default {
  name: "HomeView",
  components: {
    FilpClock,
  },
  data() {
    return {
      timer: null,
      loading: false,
      activeTab: "game_xg",
      tabIndex: 0,
      gameId: 12,
      gameHistoryList: [],
      lastGameHistoryList: [],
      noticeBarList: [],
      gameNumList: [],
      swipeBannerList: [],
      latestRound: {},
      latestTimeList: [],
      popupMessage: [],
      popupMsgIndex: 0,
      showPopupMsg: false,
      todayShowPopMsg: false,
      tmList: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
      ],
      opcGames: {
        game_xg: {
          id: 12,
          code: "game_xg",
          name: "香港六合彩",
          DrawTS: "",
        },
        game_am: {
          id: 62,
          code: "game_am",
          name: "澳门六合彩",
          DrawTS: "",
        },
        game_tz: {
          id: 9999,
          code: "game_tz",
          name: "天子六合彩",
          DrawTS: "每天48期",
        },
        game_pc28: {
          id: 11,
          code: "game_pc28",
          name: "PC28",
          DrawTS: "每天402期",
        },
      },
    };
  },
  computed: {},
  watch: {
    // issue(value) {
    //   const item = this.history[this.active].find((item) => item.issue == value);
    //   if (item) {
    //     this.game.balls = item.balls;
    //     this.game.total = item.total;
    //     const combo = getComboResult(item.total, this.games[this.active].max);
    //     this.game.bigOrSmall = combo.bigOrSmall;
    //     this.game.oddOrEven = combo.oddOrEven;
    //   }
    // },
    // showGGL(show) {
    //   if (show) {
    //     // this.$refs.canvas.init()
    //   } else {
    //     this.params.status = -1;
    //     this.$refs.canvas.reset();
    //   }
    $route() {
      this.stopPolling(); // 路由改变时清除定时器
    },
  },
  created() {
    this.getLatestTime();
    this.initScrollMsg();
    this.loaderImages();
    this.getGameResult();
    this.getAdvertiseList();
    this.getLatestRound();
    this.getPopupMessage();
  },
  beforeUnmount() {
    this.stopPolling();
  },
  methods: {
    async initScrollMsg() {
      const res = await getScrollingMessage();
      if (res) {
        this.noticeBarList = res;
      }
    },
    loaderImages() {
      const imgs = [
        require("../assets/ggk_mask.png"),
        require("../assets/bg_dd28_selected.png"),
        require("../assets/bg_game_pc28_selected.png"),
        require("../assets/bg_game_ssc_selected.png"),
        require("../assets/bg_game_k3_selected.png"),
      ];
      imgs.forEach((img) => {
        new Image().src = img;
      });
    },
    async getGameResult() {
      this.loading = this.activeTab == "game_pc28" ? false : true;
      try {
        const res = await listLotteryDrawResult(this.gameId);
        if (res && !this.isEqual(res, this.lastGameHistoryList)) {
          this.gameHistoryList = res;
          this.lastGameHistoryList = res;
          const arr = res.map((item) => {
            return this.formatNum(item.DrawResult, item.RoundNo);
          });
          this.gameNumList.push(...arr);
        }
      } finally {
        this.loading = false;
      }
    },
    startPolling() {
      if (this.activeTab == "game_pc28") {
        this.timer = setInterval(this.getGameResult, 15000);
      } else {
        this.stopPolling();
      }
    },
    stopPolling() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    isEqual(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },
    async getAdvertiseList() {
      const res = await getAdvertiseList();
      if (res) {
        this.swipeBannerList = res;
      }
    },
    async getLatestRound() {
      const res = await getLatestRound(this.gameId);
      if (res) {
        this.latestRound = JSON.parse(res);
      }
    },
    async getLatestTime() {
      const res = await getLatestTime();
      if (res) {
        const latestTimeList = JSON.parse(res);
        latestTimeList.forEach((item) => {
          for (let key in this.opcGames) {
            if (this.opcGames[key].id === item.LotteryID && this.opcGames[key].id !== 9999 && this.opcGames[key].id !== 11) {
              this.opcGames[key].DrawTS = item.DrawTS;
            }
          }
        });
      }
    },
    async getPopupMessage() {
      const res = await getPopupMessage();
      const today = new Date().toLocaleDateString();
      if (res.length) {
        this.popupMessage = res;
        if (localStorage.getItem("noAlertToday") === today) {
          this.showPopupMsg = false;
        } else {
          this.showPopupMsg = true;
        }
      }
    },
    nextMessage() {
      const index = this.popupMsgIndex + 1;
      if (this.popupMessage[index]) {
        this.popupMsgIndex = index;
      }
    },
    closeMessagePopup() {
      this.showPopupMsg = false;
    },
    switchTodayShowMsg(event) {
      const today = new Date().toLocaleDateString();
      if (localStorage.getItem("noAlertToday") && !event) {
        localStorage.removeItem("noAlertToday");
        return;
      }
      localStorage.setItem("noAlertToday", today);
    },
    numberSum(drawResult) {
      if (drawResult) {
        return drawResult
          .split(",")
          .map(Number)
          .reduce((acc, curr) => acc + curr, 0);
      }
    },
    formatPC28Num(drawResult) {
      if (drawResult) {
        return drawResult.split(",").map(Number);
      }
    },
    formatNum(drawResult, roundNo) {
      if (!drawResult) return;
      const arr = drawResult.split(",").map(Number);
      const newArr = arr.map((item) => {
        const result = {
          roundNo: roundNo,
          number: item.toString().padStart(2, "0"),
          zodiac: null,
          fiveElements: null,
          color: null,
        };
        // 查找生肖
        for (const [key, values] of Object.entries(numData.zodiac)) {
          if (values.includes(item)) {
            result.zodiac = key;
            break;
          }
        }
        // 查找五行
        for (const [key, values] of Object.entries(numData.fiveElements)) {
          if (values.includes(item)) {
            result.fiveElements = key;
            break;
          }
        }
        // 查找波色
        for (const [key, values] of Object.entries(numData.color)) {
          if (values.includes(item)) {
            result.color = key;
            break;
          }
        }
        return result;
      });
      return newArr;
    },
    async changeTab(item) {
      this.activeTab = item.code;
      this.gameId = item.id;
      this.gameNumList = [];
      this.getGameResult();
      this.startPolling();
      setTimeout(() => {
        this.getLatestRound();
      }, 1000);
    },
    onFinish() {
      setTimeout(() => {
        this.getGameResult();
      }, 2000);
      this.getLatestRound();
    },
  },
};
</script>

<style lang="scss">
$color-blue: #0b43d7;
$color-red: #ee742f;
$color-red2: #e63442;

@mixin flex($justify: center, $alignItems: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justify;
}

@mixin border($witdh: 1px, $color: #cadcfa) {
  border: $witdh solid $color;
}

.van-popup--center {
  max-width: 100vw !important;
}

.van-popup__close-icon--bottom-center {
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}

.font-din {
  font-family: "DinPro";
  font-weight: bold;
}

.notice-swipe {
  height: 40px;
  line-height: 42px;
  .van-swipe-item {
    color: #434343;
  }
}

.tabs {
  @include flex(space-between);
  margin: 8px 12px;

  .item {
    position: relative;
    width: 78px;
    height: 84px;
    border-radius: 8px;
    @include flex(center, center, column);

    &.game_xg {
      background: linear-gradient(180deg, #ffb76f 0%, #943803 100%);
    }

    &.game_am {
      background: linear-gradient(180deg, #9921cd 0%, #060fb4 100%);
    }

    &.game_tz {
      background: linear-gradient(180deg, #5fdb46 0%, #035c5c 100%);
    }

    &.game_pc28 {
      background: linear-gradient(180deg, #4ce7c3 0%, #043eab 100%);
    }

    &.active {
      height: 96px;
      animation: active 0.3s;
      animation-fill-mode: forwards;
      z-index: 10;

      .next-cd {
        @include flex;
      }

      .name {
        color: #f14400;
        margin-top: 6px;
      }

      .dateTime {
        color: #f14400;
      }
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px #00000026;
    }

    .name {
      font-size: 10px;
      font-weight: 700;
      color: #ffffff;
      margin: 6px 0px;
    }

    .dateTime {
      font-size: 9px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.latest {
  @include flex(space-between, flex-start, column);
  margin: 8px 12px;
  padding: 10px 10px 10px;
  @include border;
  border-radius: 4px;
  background: #fff;
  box-sizing: content-box;

  .current {
    @include flex(flex-start, center, row);
    width: 100%;

    .lotter {
      @include flex(center, center, column);
      width: 60px;

      .lotteryName {
        font-size: 10px;
        font-weight: 400;
        color: #000000;
        margin-top: 6px;
      }

      .logo {
        width: 58px;
        height: 58px;
      }
    }
  }

  .next {
    @include flex(flex-end, center, column);
    flex: 1;

    .lastRoundNo {
      margin-bottom: 4px;
      span {
        color: #e63442;
      }
    }

    .lastDate {
      font-size: 10px;
      font-weight: 500;
      color: #f14400;
      margin-top: 12px;
    }
  }

  .next-tzpc {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-left: 2px solid #ebebeb;
    margin-left: 20px;
    padding-left: 20px;
    .tip-title {
      font-size: 10px;
      font-weight: 600;
      color: #000;
    }
    .tip-text {
      font-size: 10px;
      font-weight: 600;
      color: #727272;
    }
  }

  .loading {
    width: 100%;
    @include flex;
    font-size: 14px;
    color: #e63442;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.tabbar {
  @include flex;
  margin: 8px 12px;
  position: relative;

  .item {
    @include flex;
    position: relative;
    flex: 1;
    padding: 5px 10px;
    background: #fff;
    color: #000;
    font-size: 14px;
    font-weight: 600;

    .history {
      flex: 1;
      text-align: center;
    }
    .more-history {
      position: absolute;
      right: 10px;
      .more {
        background-color: #2d65c6;
        padding: 2px 6px;
        font-size: 12px;
        color: #ffffff;
        border-radius: 10px;
      }
    }

    &.active {
      background: #4880e0;
      color: #ffffff;
      font-weight: bold;
    }

    &.active::before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: 6px solid #4880e0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}

.tab-content {
  padding-bottom: 50px;
  margin: 8px 12px;
  @include flex(center, center, column);

  .table {
    width: 100%;
    @include border(1px, #d7d7d7);
    border-radius: 4px;
    background-color: #fff;
    margin: 4px 0px;
    border: 1px solid #eeeeee;

    .table-list {
      display: flex;
      flex-direction: column;
      overflow-x: scroll;
      .list {
        display: flex;
        &-title {
          .item {
            background-color: #e2e2e2;
            font-size: 12px;
            font-weight: 600;
            color: #000000;
          }
        }
        .item {
          display: flex;
          flex: 1 0 auto;
          align-items: center;
          justify-content: center;
          border: 0.5px solid #eeeeee;
          height: 25px;
          &-roundNo {
            width: 100px;
          }
          &-title {
            width: 50px;
          }
          &-num {
            width: 25px;
            .tm {
              border-radius: 50%;
            }
          }
          .tm {
            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            text-align: center;
            align-items: center;
            color: #ffffff;
            font-weight: 500;
            &-red {
              background-color: #e53d3e;
            }
            &-bule {
              background-color: #1363e3;
            }
            &-green {
              background-color: #4aa031;
            }
          }
        }
      }
    }
  }

  .table-header {
    @include flex;
    justify-content: space-between;
    padding: 10px;
    .header-roundNo {
      display: flex;
      align-items: flex-end;
      p {
        margin: 0;
        padding: 0;
        color: #f14400;
      }
    }
    .header-dateTime {
      font-size: 10px;
      font-weight: 500;
      color: #f14400;
    }
  }

  .table-body {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .item {
      .ball {
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        width: 30px;
        height: 30px;
        margin-bottom: 8px;

        &-red {
          background-image: url("../assets/home/redBall.png");
        }
        &-bule {
          background-image: url("../assets/home/buleBall.png");
        }
        &-green {
          background-image: url("../assets/home/greenBall.png");
        }
        .ball-number {
          position: absolute;
          top: 4px;
          left: 6px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .ball-class {
        font-size: 12px;
        font-weight: 400;
        color: #727272;
        letter-spacing: 2px;
      }

      .icon-add {
        text-align: center;
        align-content: center;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .item:nth-child(7) {
      order: 8;
    }
    .item:nth-child(8) {
      order: 7;
    }
  }

  .row-header {
    background-color: #e2e2e2;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    div {
      text-align: center;
      padding: 4px;
      border: 0.5px solid #eeeeee;
    }
  }

  .table-col {
    text-align: center;
    padding: 4px;
    border: 0.5px solid #eeeeee;
    font-size: 12px;
    font-weight: 500;
    color: #777777;
    span {
      margin: 0px 8px;
    }
  }
}

.marquee-item {
  font-size: 10px;
  color: #434343;
}

.popupMsg {
  .dialog-text {
    .msg-dateTime {
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      color: #9e9e9e;
      margin-top: 6px;
    }
    .msg-content {
      text-align: center;
      padding: 0px 8px 8px 8px;
    }
  }
  .dialog-switch {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0px 8px 8px 8px;
    .switch-text {
      font-size: 12px;
      font-weight: 400;
      color: #3f66ce;
      margin-right: 8px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      flex: 1;
      font-weight: 600;
    }
    .btn-close {
      color: #934f00 !important;
    }
  }
}
</style>
